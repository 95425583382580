<template>
    <v-row>
        <v-col cols="12">
            <v-card class="pa-8" rounded="lg" color="primary-gray">
                <v-row>
                    <v-col cols="12">
                        <v-row align="center">
                            <v-col cols="6">
                                <h2>Pagamentos</h2>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn :disabled="pending_vouchers.length === 0" elevation="0" color="blue" dark @click="exportCsvVouchers()">Exportar CSV <v-icon right>mdi-file-download</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row align="center">
                                    <v-col cols="auto">
                                        <v-btn text @click="show_filter = !show_filter">
                                            <v-icon left>mdi-filter</v-icon> Filtros <v-icon right>{{ show_filter ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-divider />
                                    </v-col>
                                </v-row>
                                <v-slide-y-transition>
                                    <v-row v-if="show_filter" justify="center">
                                        <v-col cols="12" md="9">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-select label="Ano" v-model="year" dense :items="yearsList"></v-select>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select label="Mês" v-model="month" dense :items="months"></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-slide-y-transition>
                                <v-slide-y-transition>
                                    <v-row v-if="show_filter" align="center">
                                        <v-col>
                                            <v-divider />
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn color="blue" class="white--text" @click="getPayments()">
                                                Buscar <v-icon right>mdi-filter-check-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-slide-y-transition>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-data-table
                            v-model="selected"
                            :headers="headers_pending"
                            :items="pending_vouchers"
                            sort-by="extract_date"
                            sort-desc
                            :items-per-page="-1"
                            hide-default-footer
                            item-key="id"
                            show-select
                            :loading="loading"
                        >
                            <template v-slot:[`item.ammount_paid`]="{ item }">
                                {{ (item.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                            </template>
                            <template v-slot:[`item.extract_date`]="{ item }">
                                {{ formatDate(item.extract_date) }}
                            </template>
                            <template v-slot:[`item.payer`]="{ item }">
                                {{ item.customer.payer_name ?? item.customer.name }}
                            </template>
                            <template v-slot:[`item.payer_cpf`]="{ item }">
                                {{ item.customer.payer_cpf ?? item.customer.cpf }}
                            </template>
                            <template v-slot:[`item.without_cpf`]="{ item }">
                                <span v-if="item.without_cpf" class="text-h6">CPF NÃO INFORMADO</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <dialog-generate-vouchers v-model="show_dialog_generate" :extracts="selected" @close="getPendingVouchers()" />
    </v-row>
</template>

<script>
import dayjs from 'dayjs';
import localeData from "dayjs/plugin/localeData";
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import extenso from 'extenso';
import { mapActions } from 'vuex';
import { saveAs } from "file-saver";
require("dayjs/locale/pt-br");

import DialogGenerateVouchers from './components/DialogGenerateVouchers.vue';

export default {
    components: {
        DialogGenerateVouchers
    },
    data() {
        return {
            loading: false,
            pending_vouchers: [],
            headers_pending: [
                { text: "Cliente", value: "customer.name", sortable: true },
                { text: "CPF", value: "customer.cpf", sortable: true },
                { text: "Valor pago", value: "ammount_paid", sortable: true },
                { text: "Data do pagamento", value: "extract_date", sortable: true },
                { text: "Pagador", value: "payer", sortable: true },
                { text: "CPF doPagador", value: "payer_cpf", sortable: true },
                { text: "", value: "without_cpf", sortable: false }
            ],
            headers: [
                { text: "Referência", value: "reference_month_year" },
                { text: "Valor total", value: "ammount" },
                { text: "Pagador", value: "payer_name" },
                { text: "CPF do pagador", value: "payer_cpf" },
                { text: "Cliente", value: "customer.name" },
                { text: "CPF do cliente", value: "customer_cpf" },
                { text: "", value: "voucher" },
                { text: '', value: 'data-table-expand' },
            ],
            selected: [],
            show_dialog_generate: false,
            vouchers: [],
            show_filter: false,
            month: dayjs().subtract(1, 'month').format('MM'),
            year: dayjs().subtract(1, 'month').format('YYYY'),
            voucher_ammount: null,
            payer: [],
            customer: [],
            payers_list: [],
            customers_list: [],
            months: [
                { value: '01', text: 'Janeiro' },
                { value: '02', text: 'Fevereiro' },
                { value: '03', text: 'Março' },
                { value: '04', text: 'Abril' },
                { value: '05', text: 'Maio' },
                { value: '06', text: 'Junho' },
                { value: '07', text: 'Julho' },
                { value: '08', text: 'Agosto' },
                { value: '09', text: 'Setembro' },
                { value: '10', text: 'Outubro' },
                { value: '11', text: 'Novembro' },
                { value: '12', text: 'Dezembro' }
            ],
        };
    },
    computed: {
        yearsList() {
            let current_year = 2023;
            let years = [];

            while (current_year <= dayjs().format('YYYY')) {
                years.push(current_year.toString());
                current_year++;
            }

            return years;
        }
    },
    mounted() {
        dayjs.extend(require('dayjs/plugin/customParseFormat'));
        dayjs.extend(localeData);
        dayjs.locale('pt-br');
        this.getCustomers();
        this.getPayments();
    },
    methods: {
        ...mapActions("vouchers", ["ActionDoGetWithoutVoucherExtracts", 'ActionDoGetVouchers', 'ActionDoGetVoucher', 'ActionDoGetExtracts']),
        ...mapActions("customers", ["ActionDoGetCustomers"]),
        getCustomers() {
            this.ActionDoGetCustomers({without_relations: true}).then(response => {
                let payers = [];
                let customers = [];

                for (let customer of response) {
                    if (customer.payer_name && customer.payer_cpf) {
                        payers.push({ text: customer.payer_name, value: customer.payer_name });
                    } else {
                        payers.push({ text: customer.name, value: customer.name })
                    }

                    customers.push({ text: customer.name, value: customer.id })
                }

                this.payers_list = payers;
                this.customers_list = customers;
            });
        },
        getPendingVouchers() {
            this.loading = true;
            this.selected = [];
            this.ActionDoGetWithoutVoucherExtracts().then((pending_vouchers) => {
                this.pending_vouchers = [];
                for (let extract of pending_vouchers) {
                    if (!extract.customer.cpf && !extract.customer.payer_cpf) {
                        extract['isSelectable'] = false;
                        extract['without_cpf'] = true;
                    }
                    this.pending_vouchers.push(extract);
                }
                this.loading = false;
                this.getVouchers();
            });
        },
        getPayments() {
            this.loading = true;
            this.ActionDoGetExtracts(this.mountFilterVoucher()).then((extracts) => {
                this.pending_vouchers = [];
                for (let extract of extracts) {
                    if (!extract.customer.cpf && !extract.customer.payer_cpf) {
                        extract['isSelectable'] = false;
                        extract['without_cpf'] = true;
                    }
                    this.pending_vouchers.push(extract);
                }
                this.loading = false;
                this.getVouchers();
            });
        },
        getVouchers() {
            this.loading = true;
            this.ActionDoGetVouchers(this.mountFilterVoucher()).then(vouchers => {
                this.vouchers = vouchers;
                this.loading = false;
            });
        },
        mountFilterVoucher() {
            let filter = {};

            if (this.year && this.month) {
                filter['year'] = this.year;
                filter['month'] = this.month;
            }
            if (this.voucher_ammount) {
                filter['ammount'] = this.voucher_ammount;
            }
            if (this.payer) {
                filter['payer_name'] = this.payer;
            }
            if (this.customer) {
                filter['customer_id'] = this.customer;
            }

            return Object.values(filter).length > 0 ? filter : null;
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatReference(reference) {
            if (reference) {
                let reference_formated = dayjs(reference + '-01').format('MMMM [de] YYYY');
                return reference_formated.charAt(0).toUpperCase() + reference_formated.slice(1);
            } else {
                return null;
            }
        },
        generatePDF(voucher_id) {
            return this.ActionDoGetVoucher(voucher_id).then(voucher => {
                let options = {
                    margin: 25,
                    filename: voucher.payer_name.replace(/\s+/g, '_') + '_- Recibo_pagamento.pdf',
                    image: { type: 'jpeg', quality: 1 },
                    html2canvas: { dpi: 192, letterRendering: true },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                }

                if (voucher.payer_name === voucher.customer.name) {
                    return axios.get('/voucher/voucher_same_payer.html').then(response => {
                        let html = response.data;
                        html = html.replace('[PAYER_NAME]', voucher.payer_name);
                        html = html.replace('[PAYER_CPF]', voucher.payer_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                        html = html.replace('[AMMOUNT]', (voucher.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                        html = html.replace('[AMMOUNT_IN_WORDS]', extenso((voucher.ammount / 100).toLocaleString('pt-BR'), { mode: 'currency' }));
                        html = html.replace('[MONTH_NAME]', dayjs(voucher.reference_month_year + '-01').format('MMMM [de] YYYY'));
                        html = html.replace('[DATE]', dayjs(voucher.voucher_date).format('DD [de] MMMM [de] YYYY'));

                        html2pdf().from(html).set(options).save();
                    });
                } else {
                    return axios.get('/voucher/voucher_other_payer.html').then(response => {
                        let html = response.data;
                        html = html.replace('[PAYER_NAME]', voucher.payer_name);
                        html = html.replace('[PAYER_CPF]', voucher.payer_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                        html = html.replace('[AMMOUNT]', (voucher.ammount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
                        html = html.replace('[AMMOUNT_IN_WORDS]', extenso((voucher.ammount / 100).toLocaleString('pt-BR'), { mode: 'currency' }));
                        html = html.replace('[CUSTOMER_NAME]', voucher.customer.name);
                        html = html.replace('[CUSTOMER_CPF]', voucher.customer.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"));
                        html = html.replace('[MONTH_NAME]', dayjs(voucher.reference_month_year + '-01').format('MMMM [de] YYYY'));
                        html = html.replace('[DATE]', dayjs(voucher.voucher_date).format('DD [de] MMMM [de] YYYY'));

                        html2pdf().from(html).set(options).save();
                    });
                }
            });
        },
        exportCsvVouchers() {
            let headers = ["Pagador", "CPF do pagador", "Beneficiário", "CPF do Beneficiário", "Valor pago", "Data de Pagamento"];
            let rows = this.pending_vouchers.map(extract => {
                return [
                    extract.customer.payer_name ?? extract.customer.name,
                    extract.customer.payer_cpf ? extract.customer.payer_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : (extract.customer.cpf ? extract.customer.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "CPF NÃO INFORMADO"),
                    extract.customer.payer_cpf ? extract.customer.name : "",
                    (extract.customer.payer_cpf ? (extract.customer.cpf ? extract.customer.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"): "CPF NÃO INFORMADO") : ""),
                    (extract.ammount_paid / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                    this.formatDate(extract.extract_date),
                ];
            });

            let csv_data = [headers, ...rows];
            let csv_content = csv_data.map(row => row.join(';')).join('\n');

            let blob = new Blob([csv_content], { type: 'text/csv;charset=utf-8' });

            saveAs(blob, 'Pagamentos de ' + this.formatReference(this.year + '-' + this.month) + '.csv');
        }
    }
};
</script>
