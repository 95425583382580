import services from "../../../http/index";

export const ActionDoGetVouchers = (context, payload = null) => {
    return services.api.request({
        url: "vouchers",
        method: "get",
        params: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetVoucher = (context, voucher_id) => {
    return services.api.request({
        url: `vouchers/${voucher_id}`,
        method: "get"
    }).then(response => {
        return response.data;
    });
};

export const ActionDoCreateVoucher = (context, payload) => {
    return services.api.request({
        url: "vouchers",
        method: "post",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoUpdateVoucher = (context, payload) => {
    return services.api.request({
        url: `vouchers/${payload.voucher_id}`,
        method: "put",
        data: payload
    }).then(response => {
        return response.data;
    });
};

export const ActionDoDeleteVoucher = (context, voucher_id) => {
    return services.api.request({
        url: `vouchers/${voucher_id}`,
        method: "delete"
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetWithoutVoucherExtracts = () => {
    return services.api.request({
        url: 'extract/without-voucher',
        method: 'get'
    }).then(response => {
        return response.data;
    })
};

export const ActionDoGetExtracts = (context, payload = null) => {
    return services.api.request({
        url: 'extracts',
        method: 'get',
        params: payload
    }).then(response => {
        return response.data;
    })
};
