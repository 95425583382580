<template>
    <v-dialog v-model="show" max-width="600px" :persistent="loading" scrollable>
        <v-card class="pa-4">
            <v-card-title>
                Gerar contrato
            </v-card-title>
            <v-card-text>
                <v-form ref="formContract">
                    <v-row>
                        <v-col v-if="error_message" cols="12">
                            <v-alert type="error darken-4">
                                {{ error_message }}
                            </v-alert>
                        </v-col>
                        <v-col v-if="success_message" cols="12">
                            <v-alert type="success">
                                {{ success_message }}
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="auto">
                                    Confirme as informações do contrato
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="contractor" label="Nome do contratante" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-simplemask
                                v-model="cpf"
                                label="CPF"
                                :options="{
                                    inputMask: '###.###.###-##',
                                    outputMask: '###########',
                                    empty: null,
                                    applyAfter: false,
                                    alphanumeric: true,
                                    lowerCase: false,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="rg" label="RG" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="minutes" label="Duração (em minutos)" type="number" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="weekday"
                                label="Dia da sessão"
                                :items="[
                                    { text: 'Segunda', value: 'monday' },
                                    { text: 'Terça', value: 'tuesday' },
                                    { text: 'Quarta', value: 'wednesday' },
                                    { text: 'Quinta', value: 'thursday' },
                                    { text: 'Sexta', value: 'friday' },
                                    { text: 'Sábado', value: 'saturday' },
                                    { text: 'Domingo', value: 'sunday' }
                                ]"
                                :rules="[basic_rules.required]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menuTimePicker"
                                v-model="show_timepicker"
                                :close-on-content-click="false"
                                :return-value.sync="hour"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="hour"
                                        label="Hora da sessão"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[basic_rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="show_timepicker"
                                    v-model="hour"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menuTimePicker.save(hour)"
                                />
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="ammount"
                                label="Valor da sessão"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="payment_type" label="Forma de pagamento" :rules="[basic_rules.required]" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field-money
                                v-model="cancel_value"
                                label="Taxa de cancelamento"
                                :properties="{
                                    prefix: 'R$',
                                }"
                                :options="{
                                    locale: 'pt-BR',
                                    length: 11,
                                    precision: 2,
                                    empty: null,
                                }"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menuDatePicker"
                                v-model="show_datepicker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="formatDate(date)"
                                        label="Data do contrato"
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="$event => date = formatUsa($event)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                    editable
                                    @input="show_datepicker = false"
                                />
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-pink" elevation="0" @click="close"><v-icon left>mdi-arrow-u-left-top</v-icon> Voltar</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :disabled="loading" :loading="loading" dark color="primary-blue" elevation="0" @click="save">Gerar <v-icon right>mdi-check-bold</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'));
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        customer: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            basic_rules: {
                required: value => !!value || "Informação obrigatória"
            },
            contractor: null,
            cpf: null,
            rg: null,
            minutes: null,
            weekday: null,
            hour: null,
            ammount: null,
            payment_type: null,
            cancel_value: null,
            date: null,
            show_timepicker: false,
            show_datepicker: false,
            loading: false,
            error_message: null,
            success_message: null
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (!this.show) {
                this.contractor = null;
                this.cpf = null;
                this.rg = null;
                this.minutes = null;
                this.weekday = null;
                this.hour = null;
                this.ammount = null;
                this.payment_type = null;
                this.cancel_value = null;
                this.date = null;
                this.show_timepicker = false;
                this.show_datepicker = false;
                this.loading = false;
                this.error_message = null;
                this.success_message = null;
                this.$emit("close");
            }

            this.error_message = null;
            this.success_message = null;
            this.$emit("input", this.show);
        },
        customer() {
            if (this.customer) {
                this.contractor = this.customer.name;
                this.cpf = this.customer.cpf;
                this.rg = this.customer.rg;
                this.minutes = this.customer.session_duration;
                this.weekday = this.customer.week_day_session;
                this.hour = this.customer.hour_session;
                this.ammount = this.customer.ammount / 100;
                this.payment_type = "mensalmente, de maneira antecipada";
                this.cancel_value = 20;
                this.date = dayjs().format('YYYY-MM-DD');
            }
        }
    },
    methods: {
        ...mapActions("customers", ["ActionDoGenerateContract"]),// Parei aqui
        close() {
            this.$refs.formContract.reset();
            this.show = false;
        },
        formatDate(date) {
            return date ? dayjs(date).format("DD/MM/YYYY") : null;
        },
        formatUsa(date) {
            if (date) {
                if (date.length === 8) {
                    return dayjs(date, 'DDMMYYYY').format('YYYY-MM-DD');
                } else if (date.length === 10) {
                    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
            }

            return null;
        },
        save() {
            this.loading = true;
            this.error_message = null;
            this.success_message = null;

            if (this.$refs.formContract.validate()) {
                this.ActionDoGenerateContract({
                    contractor: this.contractor,
                    cpf: this.cpf,
                    rg: this.rg,
                    minutes: this.minutes,
                    weekday: this.weekday,
                    hour: this.hour,
                    value: Number.isInteger(this.ammount) ? this.ammount * 100 : parseInt(this.ammount.toString().replace(/[^0-9]+/g, "")),
                    payment_type: this.payment_type,
                    cancel_value: Number.isInteger(this.cancel_value) ? this.cancel_value * 100 : parseInt(this.cancel_value.toString().replace(/[^0-9]+/g, "")),
                    date: this.date,
                    customer_id: this.customer.id
                }).then(response => {
                    if (response.contract) {
                        this.success_message = "Contrato cadastrado com sucesso";
                        this.$refs.formContract.reset();
                        this.loading = false;
                        this.$emit("created");
                        this.show = false;
                    } else {
                        this.error_message = "Houve um erro ao cadastrar o contrato";
                        this.loading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.error_message = error.response.data;
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
